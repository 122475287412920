<template lang="">
    <ContainerVue max-w="6xl">
        <section class="text-gray-700">
            <div class="text-center mb-10">
                <h1 class="sm:text-3xl text-2xl font-medium text-center title-font text-gray-900 mb-4">
                    Frequently Asked Question
                </h1>
                <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    The most common questions about how our retina organization and what
                    can do for you.
                </p>
            </div>

            <div class="flex flex-wrap lg:w-4/4 sm:mx-auto sm:mb-2 -mx-2">
                <FaqCardVue v-for="faq in faqs" :faq="faq"/>
            </div>
        </section>
    </ContainerVue>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ContainerVue from '../../layouts/container.vue';
    import FaqCardVue from './faq-card.vue';

    export default {
        name: 'faq',
        components: {
            ContainerVue,
            FaqCardVue
        },
        data: {

        },
        computed: {
            ...mapGetters({
                faqs: 'faqs/faqs',
            }),
        },
        created() {
            this.$store.dispatch('faqs/faqData');
        },
    }
</script>

<style lang="">
    
</style>